'use client';

import { Alarm, AutoGraph, MarkEmailRead, Science } from '@labServices/utils/svg';
import { useRouter } from 'next/navigation';

import Button from '@/labtest/presentation/atoms/Button';
import styles from './LabTestOrderSuccess.module.scss';

const LabTestOrderSuccess = ({ data, closeOrderSuccessModal }) => {
	const router = useRouter();
	const handlerViewOrderDetails = () => {
		closeOrderSuccessModal();

		router.push(`/account/labTest/orders/labtest-orders/${data?.id}`);
	};
	const handlerCreateNewOrder = () => {
		closeOrderSuccessModal();

		router.push('/lab-test/tests');
	};

	return (
		<>
			<div className={styles.order_details_container}>
				<div className={styles.order_info}>
					<div className={styles.order_id}>
						<div className={styles.icon}>
							<AutoGraph />
						</div>
						<div className={styles.order_id_info}>
							<div>Order ID</div>
							<div>#{data?.orderNumber}</div>
						</div>
					</div>
					<div className={styles.order_item}>
						<div className={styles.icon}>
							<Science />
						</div>
						<div className={styles.order_item_info}>
							<div>Order Item</div>
							{data?.packageCount > 0 && (
								<div>
									{data?.packageCount} {data?.packageCount > 1 ? 'Packages' : 'Package'} for{' '}
									{data?.items?.reduce((previous, current) => previous + current.patientCount, 0)}{' '}
									{data?.items?.reduce((previous, current) => previous + current.patientCount, 0) > 1
										? 'Patients'
										: 'Patient'}
								</div>
							)}
							{data?.testCount > 0 && (
								<div>
									{data?.testCount} {data?.testCount > 1 ? 'Tests' : 'Test'} for{' '}
									{data?.items?.reduce((previous, current) => previous + current.patientCount, 0)}{' '}
									{data?.items?.reduce((previous, current) => previous + current.patientCount, 0) > 1
										? 'Patients'
										: 'Patient'}
								</div>
							)}
							{data?.packageCount > 0 && data?.testCount > 0 && (
								<div>
									{data?.packageCount} {data?.packageCount > 1 ? 'Packages' : 'Package'} & {data?.testCount}{' '}
									{data?.testCount > 1 ? 'Tests' : 'Test'} for{' '}
									{data?.items?.reduce((previous, current) => previous + current.patientCount, 0)}{' '}
									{data?.items?.reduce((previous, current) => previous + current.patientCount, 0) > 1
										? 'Patients'
										: 'Patient'}
								</div>
							)}
						</div>
					</div>
					<div className={styles.order_sample_collection}>
						<div className={styles.icon}>
							<Alarm />
						</div>
						<div className={styles.order_sample_collection_info}>
							<div>Sample Collection Schedule</div>
							<div>
								{data?.formattedScheduleDate}, {data?.scheduleTimeRange?.en}
							</div>
						</div>
					</div>
					<div className={styles.order_confirmation}>
						<div className={styles.icon}>
							<MarkEmailRead />
						</div>
						<div className={styles.order_confirmation_info}>
							<div>Confirmation will be sent to your Arogga App, SMS</div>
							<div>Check your Arogga App or Phone SMS</div>
						</div>
					</div>
				</div>
			</div>

			<div className={`${styles.button_container} ${styles.button_container_orderCancel}`}>
				<Button variant='bgOrange_01' color='clOrange' fullWidth onClick={() => handlerViewOrderDetails()}>
					View order details
				</Button>
				<Button variant='bgPrimary' fullWidth onClick={() => handlerCreateNewOrder()}>
					Create new order
				</Button>
			</div>
		</>
	);
};

export default LabTestOrderSuccess;
