'use client';

import lottie from 'lottie-web';
import { useRouter } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';

import animated_graphic from './data.json';

// import { IG_DoctorConsultationPaymentInfo } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationPaymentInfo';
// import { BookingPlacedSVG, PayOnlineSVG } from '@/services/doc-consultation/utils/iconBase';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import LabTestOrderSuccess from '@/labtest/presentation/organisms/OrderSuccessModal/LabTestOrderSuccess';

// import Line from '../../atoms/Line';

// import ConsultPatientProblemsCard from '@/doc-consultation/presentation/molecules/DoctorPortal/ConsultPatientProblemsCard';
// import PaymentDetailsCard from '@/doc-consultation/presentation/molecules/PatientPaymentInfo/PaymentDetailsCard';
// import PaymentMethodInformation from '@/doc-consultation/presentation/molecules/PatientPaymentInfo/PaymentMethodInfo';
// import EditPatientConsultationReason from '@/doc-consultation/presentation/organisms/MyConsultationDetails/EditPatientConsultationReason';
// import {
// 	getDocConsultationDetails,
// 	onlinePaymentForConsultation
// } from '@/services/doc-consultation/apis/doc-consultation-apis/doc-consultation';
// import { IG_CheckoutDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationCheckout';
// import { IG_DoctorConsultationDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationDetails';
// import { monitorPopupWindow } from '@/services/lab-test/utils/cmnHelper';
// import { toast } from 'react-toastify';
// import Button from '../../atoms/Button';
// import DocConsultationOrderSuccess from './DocConsultationOrderSuccess';
import styles from './OrderSuccessModal.module.scss';

interface I_Props {
	serviceType: string;
	// data: Partial<IG_DoctorConsultationPaymentInfo>;
	// checkoutData?: Partial<IG_CheckoutDetails>;
	// setCheckoutData?: React.Dispatch<React.SetStateAction<Partial<IG_CheckoutDetails>>>;
	title: string;
	message: string;
	closeOrderSuccessModal: () => void;
	closeCheckoutModal?: () => void;
}

const OrderSuccessModal = ({
	serviceType,
	// data,
	// checkoutData,
	// setCheckoutData,
	title,
	message,
	closeOrderSuccessModal,
	closeCheckoutModal
}: I_Props) => {
	const [viewDetails, setViewDetails] = useState<boolean>(false);
	const [isBookedAppointment, setIsBookedAppointment] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(true);
	// const [consultationDetails, setConsultationDetails] = useState<Partial<IG_DoctorConsultationDetails>>({});
	const [isSaveInfo, setIsSaveInfo] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const router = useRouter();
	const editInfo = useRef();

	// const handleGetConsultationDetails = async () => {
	// 	setIsLoading(true);
	// 	const res = await getDocConsultationDetails(data?.id);

	// 	if (res?.success) {
	// 		setConsultationDetails(res?.data);

	// 		setIsEdit(false);

	// 		// Change Problem Details Card to Edit Mode if consultationReason is not provided
	// 		// if (!res?.data?.consultationReason) {
	// 		// 	setIsEdit(true);
	// 		// }
	// 	}

	// 	setIsLoading(false);
	// };

	// const handleSaveAdditionalInfo = () => {
	// 	setIsSaveInfo(true);

	// 	if (editInfo.current) {
	// 		editInfo.current.triggerSave();
	// 	}
	// };

	// const handlerPayOnline = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
	// 	e.preventDefault();

	// 	const res = await onlinePaymentForConsultation(data?.id);
	// 	if (res?.success) {
	// 		monitorPopupWindow(`${res?.data?.paymentUrl}`, 'Arogga Payment', window, 650, 700, () => {
	// 			// handleGetConsultationDetails();
	// 			// router.push(`/account/doctorConsultation/my-consultations/${consultationDetails?.id}`);
	// 			setIsBookedAppointment(true);
	// 		});
	// 	} else {
	// 		toast.error(res?.message);
	// 		// handleGetConsultationDetails();
	// 	}
	// };

	useEffect(() => {
		if (typeof window !== 'undefined' && typeof document !== 'undefined') {
			const animation = lottie.loadAnimation({
				loop: true,
				renderer: 'svg',
				animationData: animated_graphic,
				container: document.getElementById('animated_graphic')
			});
			return () => animation.destroy();
		}
	}, []);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.details_container}>
					<div className={styles.icon_container_wrap}>
						<div className={styles.order_success_graphic} id='animated_graphic'></div>

						<div className={styles.content_container}>
							<h1>{title}</h1>
							<p>{message}</p>
						</div>

						<div className={styles.booking_track_icons}>
							<div className={styles.track_icon}>
								{/* <BookingPlacedSVG /> */}
								{/* <Line /> */}
								{/* <PayOnlineSVG /> */}
							</div>

							<div className={styles.track_icon_text}>
								<p className='ltbody_md__regular ltSuccessBase t_nowrap'>Booking Placed</p>
								<p className='ltbody_md__regular ltGreyscale600 t_nowrap'>Pay Online</p>
							</div>
						</div>
					</div>

					{viewDetails && (
						<>
							{serviceType === 'lab-test' && (
								<LabTestOrderSuccess data={data} closeOrderSuccessModal={closeOrderSuccessModal} />
							)}

							{/* {serviceType === 'doc-consultation' && (
								<DocConsultationOrderSuccess data={data} closeOrderSuccessModal={closeOrderSuccessModal} />
							)} */}
						</>
					)}

					<div
						className={`${styles.view_details_btn} flex gap-4 items-center justify-center ltbody_md__medium primaryBase pb-20`}
						onClick={() => setViewDetails(!viewDetails)}>
						<p>View details</p> {viewDetails ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
					</div>
				</div>

				{/* 	{serviceType === 'doc-consultation' && isBookedAppointment ? (
					<>
						{isEdit ? (
							<EditPatientConsultationReason
								ref={editInfo}
								consultation={data}
								consultationId={data?.id}
								handleGetConsultationDetails={handleGetConsultationDetails}
								isEdit={isEdit}
								// setIsEdit={setIsEdit}
								// setIsSaveInfo={setIsSaveInfo}
								page='order_success_modal'
							/>
						) : (
							<ConsultPatientProblemsCard
								data={consultationDetails}
								isEdit={isEdit}
								setIsEdit={setIsEdit}
								setIsSaveInfo={setIsSaveInfo}
								type='doctor'
							/>
						)}
					</>
				) : (
					<>
						<div className={styles.payment_container}>
							<PaymentMethodInformation />
						</div>
						<div className={styles.payment_container}>
							<PaymentDetailsCard data={data} page='order_success_modal' />
						</div>
					</>
				)} */}
			</div>

			<div className={`${styles.button_container} ${styles.button_container_orderCancel}`}>
				{/* {serviceType === 'doc-consultation' && (
					<>
						{isBookedAppointment ? (
							<>
								{isSaveInfo ? (
									<Button
										fullWidth
										onClick={() => router.push(`/account/doctor-consultation/my-consultations/${data?.id}`)}>
										View Details
									</Button>
								) : (
									<Button fullWidth onClick={handleSaveAdditionalInfo}>
										Save Now
									</Button>
								)}
								<div className='flex gap-20 fullWidth'>
									<Button
										variant={isSaveInfo ? 'bgWhitePrimary' : 'disabled'}
										color={isSaveInfo && 'clGrey900'}
										fullWidth
										onClick={() =>
											// isSaveInfo ? setIsEdit(false) : toast.error('Consultation Reason must be provided')
											isSaveInfo && setIsEdit(false)
										}>
										Connect with Doctor
									</Button>
								</div>
							</>
						) : (
							<Button fullWidth onClick={handlerPayOnline}>
								Pay online: ৳{data?.totalAmount}
							</Button>
						)}
					</>
				)} */}
			</div>
		</>
	);
};

export default OrderSuccessModal;
